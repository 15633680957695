.pkmn-app {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.controls {
  display: flex;
  padding: 20px 20px 0;
  justify-content: center;
  align-items: center;
}

.controls .option {
  margin: 0 10px;
}

.controls input {
  /*margin: 0 5px;*/
}

.pkmn-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.pkmn-container.section {
  padding-top: 20px;
}

.pokemon {
  image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
  /*padding: 3px;*/
  transition: background-color .2s;
  position: relative;
}

.background {
  border-radius: 4px;
  transition: .2s;
}

.background.hovered {
  background-color: #b4c0ff;
}

.pokemon .sprite {
  background-size: cover;
  transition: .5s;
}

.pokemon.not-caught .sprite {
  filter: brightness(50%) saturate(0);
  transition: .3s;
}

.pokemon.caught .sprite {
  filter: brightness(100%);
}

.pokemon.caught .sprite {
  filter: saturate(1);
}

.pokemon-items {
  position: absolute;
  font-size: 10px;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.pokemon-item {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
}

.pokemon-item:hover {
  text-decoration: underline;
}

.pokemon-item.catch {
  background-color: darkblue;
}

.pokemon-item.nickname {
  background-color: rebeccapurple;
}

.pokemon-nickname {
  z-index: 2;
  position: absolute;
  padding: 0 1px;
  right: 0;
  bottom: 0;
  background: white;
  color: black;
}
